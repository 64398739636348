<template>
    <div class="app-body">
        <bread-crumb></bread-crumb>
        <el-card class="el-main analysisContent" id="analysisContent" ref="analysisContent">
            <el-form :model="form" ref="form" :rules="rules" label-width="145px" label-position="left">
                <el-form-item label="观察名称" prop="name" >
                    <el-input v-model="form.name" maxlength="24" style="width:240px" placeholder="请输入名称"></el-input>
                </el-form-item>
                <el-form-item label="单插槽达标值" prop="observeGradeList" >
                    <div class="a-flex-rfsc">
                        <el-input
                            clearable 
                            style="width: 300px;margin-right: 12px;" 
                            v-model="gradeValue" 
                            maxlength="20" 
                            placeholder="请输入单插槽达标值" 
                            @keyup.enter.native="addGradeValue"></el-input>
                        <el-button icon="el-icon-plus" type="primary" class="s-btn-add" @click="addGradeValue">添加</el-button>
                    </div>
                    <span class="a-c-error a-fs-12">点击选项设置默认达标值</span>
                    <div class="a-flex-rfsc a-flex-wrap a-mt-20" style="width: 800px;">
                        <div class="a-flex-rfsc send-user" v-for="(item,index) in form.observeGradeList" :key="index" @click="setDefault(index)">
                            <span class="send-user-name">￥{{ item.gradeValue }}{{ item.isDefault?'（默认）':'' }}</span>
                            <!-- <span class="send-user-line"></span> -->
                            <i class="el-icon-close a-c-white a-ml-5" @click.stop="removeGradeValue(index)"></i>
                        </div>
                    </div>
                </el-form-item>
                <span class="a-fs-16 a-c-333 a-fw-b a-mt-20 block">观察站点</span>
                <el-form-item label="" prop="stationIdList" label-width='0'>
                    <div class="">
                        <choose-station ref="chooseStation" @selectChange='selectChange' :defaultData='form.stationInfoList'></choose-station>
                    </div>
                </el-form-item>
                <div style="height: 50px;"></div>
            </el-form>
            <div class="a-line-t-e0 footerBox">
                <el-button type="primary" class="a-ml-24 a-mt-15" @click="submit">立即提交</el-button>
                <el-button class="a-ml-24 a-mt-15" style="margin-left: 24px" @click="cancelSubmit">返回</el-button>
            </div>
        </el-card>
    </div>
</template>

<script>
import chooseStation from '@/views/observe/child/chooseStation'
export default {
    components: {
        chooseStation
    },
    data () {
        return {
            observeId: '',
            gradeId: '',
            form: {
                name: '',//观察名称
                observeId: '',
                observeGradeList: [],
                stationIdList: []
            },
            rules: {
                name: [{required: true, message:'请输入观察名称', trigger: 'blur'}],//观察名称
            },
            gradeValue: ''
        }
    },
    mounted () {
        this.observeId = this.$route.query.observeId
        this.gradeId = this.$route.query.gradeId
        if(this.observeId) {
            this.getObserveDetail()
        }
    },
    methods:{
        getObserveDetail () {
            this.$Axios._post({
                url: this.$Config.apiUrl.getObserveDetail,
                method: "post",
                params: {
                    id: this.observeId
                }
            }).then(res => {
                if (res.result.code == 0) {
                    let resData = {
                        ...res.result.data,
                        stationIdList: res.result.data.stationInfoList.map(item=>{
                            return item.stationId
                        })
                    }
                    resData.observeGradeList = resData.observeGradeList.map(item=>{
                        return {
                            ...item,
                            gradeValue: Number((item.gradeValue/100).toFixed(2)),
                        }
                    })
                    this.form = resData
                } else {
                    this.$message.error(res.result.message)
                }
            })
        },
        addGradeValue () {
            if(!this.gradeValue){
                this.$message.error('请先填写达标值')
                return
            }
            if(this.form.observeGradeList.length >= 3) {
                this.$message.error('最多可添加3个达标值')
                return
            }
            if(this.form.observeGradeList.find(res=>{ return res.gradeValue == this.gradeValue })) {
                this.$message.error('已存在达标值：' + this.gradeValue)
                return
            }
            this.form.observeGradeList.push({
                gradeValue: this.gradeValue,
                isDefault: this.form.observeGradeList.length?0:1
            })
            if(this.observeId) {
                let reqData = {
                    ...this.form
                }
                reqData.observeGradeList = reqData.observeGradeList.map(res=>{
                    return {
                        ...res,
                        gradeValue: Number((res.gradeValue*100).toFixed(0))
                    }
                })
                this.$Axios._post({
                    url: this.$Config.apiUrl.updateObserveGrade,
                    method: "post",
                    params: {
                        observeId: this.observeId,
                        observeGradeList: reqData.observeGradeList
                    }
                })
                .then(res=>{
                    if (res.result.code == 0) {
                        
                    } else {
                        this.$message.error(res.result.message)
                    }
                })
                .catch(err=>{

                })
            }
            this.gradeValue = ''
        },
        setDefault (index) {
            this.form.observeGradeList = this.form.observeGradeList.map(res=>{
                return {
                    ...res,
                    isDefault: 0
                }
            })
            this.form.observeGradeList[index].isDefault = 1
        },
        removeGradeValue (index) {
            if(this.form.observeGradeList.length <= 1 && this.observeId) {
                this.$message.error('至少保留一个达标值')
                return
            }
            let isDeleteDefault = false
            if(this.form.observeGradeList[index].isDefault){ // 当前删除的达标值是否为默认达标值，若是则将删除后的第一个达标值设为默认
                isDeleteDefault = true
            }
            this.form.observeGradeList.splice(index,1)
            if(isDeleteDefault){
                this.form.observeGradeList[0].isDefault = 1
            }
            if(this.observeId) {
                let reqData = {
                    ...this.form
                }
                reqData.observeGradeList = reqData.observeGradeList.map(res=>{
                    return {
                        ...res,
                        gradeValue: Number((res.gradeValue*100).toFixed(0))
                    }
                })
                this.$Axios._post({
                    url: this.$Config.apiUrl.updateObserveGrade,
                    method: "post",
                    params: {
                        observeId: this.observeId,
                        observeGradeList: reqData.observeGradeList
                    }
                })
                .then(res=>{
                    if (res.result.code == 0) {
                        
                    } else {
                        this.$message.error(res.result.message)
                    }
                })
                .catch(err=>{

                })
            }
        },
        selectChange (datas) {
            this.form.stationIdList = datas
        },
        submit () {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    if(!this.form.observeGradeList.length) {
                        this.$message.error('请至少添加一个达标值')
                        return
                    }
                    if(!this.form.stationIdList.length) {
                        this.$message.error('请至少选择一个站点')
                        return
                    }
                    let reqData = {
                        ...this.form
                    }
                    reqData.observeGradeList = reqData.observeGradeList.map(res=>{
                        return {
                            ...res,
                            gradeValue: Number((res.gradeValue*100).toFixed(0))
                        }
                    })
                    this.$confirm('是否确认提交？','温馨提示')
                    .then(_=>{
                        this.$Axios._post({
                            url: this.$Config.apiUrl.getObserveAdd,
                            method: "post",
                            params: reqData
                        })
                        .then(res=>{
                            if (res.result.code == 0) {
                                this.$message.success('操作成功')
                                this.$router.back()
                                this.$store.dispatch("delView", this.$route);
                            } else {
                                this.$message.error(res.result.message)
                            }
                        })
                        .catch(err=>{

                        })
                    })
                    .catch(_=>{})
                }else{
                    this.$message.error('请检查所填参数是否正确')
                }
            })
        },
        cancelSubmit () {
            this.$confirm('是否确认返回？','温馨提示')
            .then(_=>{
                this.$router.back()
                this.$store.dispatch("delView", this.$route);
            })
            .catch(_=>{})
        }
    },
}
</script>

<style lang="scss" scoped>
.send-user{
    background: #53A8FF;
    color: #ffffff;
    border-radius: 4px;
    padding: 0 10px;
    margin: 0 10px 10px 0;
}
</style>
<template>
    <div>
        <le-search-form @reset="handlerRest" @search="handlerSearch" :canPackUp="true" id="leSearch">
            <le-input-district-choose ref="districtChoose" 
                label='省市区'
                :province.sync="provinceCode" 
                :city.sync="cityCode" 
                :area.sync="areaCode"
                ></le-input-district-choose>
            <le-select-remote-search label="项目" v-model="incomeId" :options="optionsincomeDistri" placeholder="请选择（可输入搜索）" />
            <le-input label="站点名称" v-model="stationName" />
            <le-input label="站点ID" v-model="stationId" />
        </le-search-form>
        <div class="a-flex-rfsfs" style="align-items: stretch;width:900px">
            <el-card class="box-card a-ml-20 a-flex-1"  shadow="never">
                <div slot="header" class="clearfix">
                    <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
                </div>
                <div class="chooseList a-flex-cfsfs" v-infinite-scroll="pageReachBottom" :infinite-scroll-distance='50' >
                    <el-checkbox-group v-model="selectDataValue" @change="handleCheckedChange" class="a-plr-10">
                        <div class="a-flex-cfsfs">
                            <el-checkbox 
                                v-for="(item,index) in tableData" 
                                :key="index" 
                                :label="item.stationId">
                                {{ item.name || '-' }}（ID：{{ item.stationId || '-' }}）
                            </el-checkbox>
                        </div>
                        
                    </el-checkbox-group>
                    <div class="a-flex-rcc a-c-999 a-fs-14" v-if="tableData.length && pageNum>1">
                    <i class="el-icon-loading a-mlr-10" v-if="loadingType == 'loading'"></i>
                    <span>{{ loadingTypeText[loadingType] }}</span>
                </div>
                </div>
                
            </el-card>
            <el-card class="box-card a-ml-20 a-flex-1" shadow="never">
                <div slot="header" class="clearfix">
                    <span>已选择表格数据：</span>
                </div>
                <div v-if="multiple" class="chooseList a-pl-10">
                    <div class="a-flex-rsbc" v-for="(item) in selectData" :key="item.stationId" style="margin-bottom: 0px;">
                        <span>{{ item.name }}</span>
                        <i class="el-icon-error a-fs-14 a-mlr-12 a-c-999" @click="removeChoose(item)"></i>
                    </div>
                    <div class="a-flex-rcc a-c-999" v-if="!selectData || !selectData.length">暂无数据</div>
                </div>
                <div v-else class="chooseList">
                    <div class="a-flex-rsbc" v-if="selectData" style="margin-bottom: 18px;">
                        <span>{{ item.name }}</span>
                        <i class="el-icon-error a-fs-14 a-ml-5 a-c-999" @click="removeChoose()"></i>
                    </div>
                    <div class="a-flex-rcc a-c-999" v-else>暂无数据</div>
                </div>
            </el-card>
        </div>
    </div>
</template>

<script>
    import util from '../../../utils/util'
    export default {
        data() {
            return {
                optionsincomeDistri: {
                    url: this.$Config.apiUrl.incomeDistriList,
                    method: "post",
                    params: {
                        queryCompanyId: ''
                    },
                    showLoading: false,
                    labelKey: "name",
                    valueKey: "id",
                    searchKey: "name",
                },//项目数据
                util: util,
                tableData: [],
                tableDataAll: [],//存储所有查询出来的列表
                selectData: [],//选中的列表
                selectDataValue: [],//选中的列表
                isIndeterminate: true,
                checkAll: false,
                cityCode: '',
                areaCode: '',
                provinceCode: '',
                incomeId: '',
                stationName: '',
                stationId: '',
                pageNum: 0,
                pageSize: 50,
                loadingType: 'more',
                loadingTypeText: {
                    'more': '下滑显示更多',
                    'noMore': '没有更多了~',
                    'loading': '加载中...'
                }
            };
        },
        props:{
            defaultData:{
                default: null,
            },
            multiple:{
                default: true
            }
        },
        watch: {
            defaultData:{
                deep: true,
                immediate: true,
                handler () {
                    if(this.multiple){
                        if(this.defaultData){
                            this.selectData = this.defaultData.map(res=>{
                                return {
                                    stationId: res.stationId,
                                    name: res.stationName
                                }
                            })
                            this.selectDataValue = this.defaultData.map(res=>{
                                return res.stationId
                            })
                        }
                    }else{
                        if(this.selectData){
                            let obj = this.tableData.find(res=>{
                                return res.id == this.selectData.id
                            })
                            this.selectData = obj || this.selectData
                        }
                    }
                }
            },
        },
        created () {
            
        },
        methods:{
            handleCheckAllChange (val) {
                if(val) {
                    let selectDataValue = this.tableData.map(res=>{
                        return res.stationId
                    })
                    this.selectDataValue = this.uniqueArr(this.selectDataValue.concat(selectDataValue))
                    this.selectData = this.uniqueArr(this.selectData.concat(this.tableData))
                }else{
                    this.tableData.map(res=>{
                        this.selectData.splice(this.selectData.indexOf(res), 1);
                        this.selectDataValue.splice(this.selectDataValue.indexOf(res.stationId), 1)
                    })
                }
                this.$emit('selectChange',this.selectDataValue)
                this.isIndeterminate = false;
            },
            handlerRest() {
                this.provinceCode = ''
                this.cityCode = ''
                this.areaCode = ''
                this.incomeId = ''
                this.stationName = ''
                this.stationId = ''
                this.handlerSearch()
            },
            handlerSearch() {
                this.pageNum = 1
                this.getTableData()
            },
            // 滑动到底部
            pageReachBottom () {
				if(this.loadingType === 'more'){
					this.pageNum++
					this.loadingType = 'loading'
					this.getTableData()
				}
			},
            //获取列表
            getTableData() {
                this.$Axios._post({
                    url: this.$Config.apiUrl.getStationList,
                    params: {
                        pageNum: this.pageNum,
                        pageSize: this.pageSize,
                        provinceCode: this.provinceCode,
                        cityCode: this.cityCode,
                        areaCode: this.areaCode,
                        incomeId: this.incomeId,
                        name: this.stationName,
                        stationId: this.stationId,
                        status: 2
                    },
                }).then((res) => {
                    if(this.pageNum == 1){
                        this.tableData = res.result.data.list
                    }else{
                        this.tableData = this.tableData.concat(res.result.data.list)
                    }
                    this.tableDataAll = this.uniqueArr(this.tableDataAll.concat(res.result.data.list))
                    if(res.result.data.list.length){
                        this.loadingType = 'more'
                    }else{
                        this.loadingType = 'noMore'
                    }
                }).catch((res) => {

                })
            },
            handleCheckedChange () {
                let otherData = this.diffArr(this.selectData, this.tableDataAll)
                this.selectData = this.tableDataAll.filter(res=>{
                    return this.selectDataValue.indexOf(res.stationId) != -1
                }).concat(otherData)
                this.$emit('selectChange',this.selectDataValue)
            },
            removeChoose (datas) {
                this.$confirm('是否确认删除？','温馨提示')
                .then(_=>{
                    if(this.multiple){
                        this.selectData.splice(this.selectData.indexOf(datas), 1);
                        this.selectDataValue.splice(this.selectDataValue.indexOf(datas.stationId), 1)
                    }else{
                        this.selectData = null
                    }
                    this.$emit('selectChange',this.selectDataValue)
                })
                .catch(_=>{})
                
            },
            uniqueArr (arr) {
                return [...new Set(arr.map(t=>JSON.stringify(t)))].map(s=>JSON.parse(s))
            },
            diffArr (array1, array2) {
                const set1 = new Set(array1.map(item => JSON.stringify(item)));
                const set2 = new Set(array2.map(item => JSON.stringify(item)));

                const difference = [...set1].filter(item => !set2.has(item));
                const result = difference.map(item => JSON.parse(item));
                return result
            }
        }
    }
</script>

<style lang="scss" scoped>
    /deep/ .el-dialog__body{
        padding: 0px 20px;
    }
    /deep/ .el-checkbox__label {
        display: inline-grid;
        width:300px;
        white-space: pre-line;
        word-wrap: break-word;
        line-height: 20px;
    }
    .stationTag{
        padding: 0px 6px;
        background: #f4f4f5;
        color: #909399;
        border: 1px solid #e9e9eb;
        border-radius: 3px;
        margin-right: 4px;
        margin-bottom: 4px;
        font-size: 12px;
        word-break: keep-all;
    }
    .chooseList{
        overflow:auto;
        width: 100%;
        // height: 400px;
        max-height: 400px;
    }
    .a-pl-10{
        padding-left: 10px;
    }
</style>